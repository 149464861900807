import React from 'react';
import { message, Modal, Typography } from "antd";
import titleize from 'titleize';

const { Text } = Typography;

const DeleteModal = ({ entity, visible, setVisibility, entityId, deleteFunction, onSuccess }) => {
    return (<Modal
        title={<Text type="danger">Delete {titleize(entity)}</Text>}
        visible={visible}
        onCancel={() => setVisibility(false)}
        okType="danger"
        onOk={async () => {
            await deleteFunction(entityId);
            onSuccess();
            message.success(`${titleize(entity)} successfully deleted.`)
            setVisibility(false)
        }}
      >
          Are you sure you want to delete this <strong>{entity}</strong>?
      </Modal>)
}

export default DeleteModal;